const BillingMenu = [
  {
    heading: "menu",
    route: "/training-partners-info",
    pages: [
      {
        sectionTitle: "Enrollment(Claim-1)",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Claim-1 Bill Submit",
            route: "/training-partners-info/claim-1-bill",
          },
          {
            heading: "Submitted Bills",
            route: "/training-partners-info/claim-1-submitted-bills",
          },
        ],
      },
      {
        sectionTitle: "Certification(Claim-2)",
        route: "/training-partners-info/training-certificate",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Claim-2 Bill Submit",
            route: "/training-partners-info/claim-2-bill",
          },
          {
            heading: "Submitted Bills",
            route: "/training-partners-info/claim-2-submitted-bills",
          },
          {
            heading: "Reassessment Bills Submit",
            route: "/training-partners-info/reassessment-bills-submit",
          },
          {
            heading: "Reassessment Submitted Bills",
            route: "/training-partners-info/reassessment-submitted-bills",
          },
        ],
      },
      {
        sectionTitle: "Employment(Claim-3)",
        route: "/training-partners-info/training-certificate-Information",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Claim-3 Bill Submit",
            route: "/training-partners-info/claim-3-bill",
          },
          {
            heading: "Submitted Bills",
            route: "/training-partners-info/claim-3-submitted-bills",
          },
        ],
      },
      {
        heading: "Archive Bill",
        route: "/training-partners-info/archive-bill",
        fontIcon: "bi-app-indicator",
        svgIcon: "media/icons/duotune/general/gen025.svg",
      },
      // {
      //   sectionTitle: "Bill Adjustment",
      //   route: "/training-partners-info/training-certificate-Information",
      //   fontIcon: "fa-info",
      //   sub: [
      //     {
      //       heading: "Enrollment Bill Adjustment",
      //       route: "/training-partners-info/enrollment-bill-adjustment",
      //     },
      //     {
      //       heading: "Certification Bill Adjustment",
      //       route: "/training-partners-info/certification-bill-adjustment",
      //     },
      //     {
      //       heading: "Employment Bill Adjustment",
      //       route: "/training-partners-info/employment-bill-adjustment",
      //     },
      //   ],
      // },
      // {
      //   heading: "Bill Adjustment",
      //   route: "/training-partners-info/bill-adjustment",
      //   fontIcon: "fa-info",
      // },
      // {
      //   heading: "Manual Bill Adjustment Moderator",
      //   route: "/training-partners-info/manual-bill-adjustment",
      //   fontIcon: "fa-info",
      // },
    ],
  },
];

export default BillingMenu;
